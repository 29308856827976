import React, { useCallback, useEffect, useState } from "react"
import Layout from "~components/layout"
import { css, Global } from "@emotion/react"
import { Grid } from "~styles/global"
import { HeaderTitle } from "~context/siteContext"
import SEO from "~components/seo"
import Player from "@vimeo/player"
import { PlayButton } from "../components/svg"
import RichText from "~components/richText"


const Video = ({ pageContext, data }) => {
    const { /* title, type, */ content } = pageContext
    const [play, setPlay] = useState(false)
    const [player, setPlayer] = useState(null)

    let videoId = content.vimeoVideo.oEmbedData.video_id

    // does not work
    if (content.vimeoVideo?.url?.includes(`event`)){
      videoId = content.vimeoVideo?.url?.split(`event/`)[1]
    }

    // let player 
    const videoContainer = useCallback(node => {
      let options = {
        id: videoId,
        loop: true,
        byline: false,
        title: false,
        portrait: false,
      }
      // if(node) player = new Player(node, options)
      if(node) setPlayer(new Player(node, options))
    }, [])

    const playVideo = () => {
      setPlay(true)
      player && player.play()
    }

   useEffect(()=> {
    if (player) {
      player.on('play', function() {
        setPlay(true)
      })
      player.on('pause', function() {
        setPlay(false)
      })
    }
   }, [player])
  
    return(
      <Layout>
        <SEO
          title={content.vimeoVideo.oEmbedData.title}
          image={content.vimeoVideo.oEmbedData.thumbnail_url}
          description={content.vimeoVideo.oEmbedData.description}
          />

        <HeaderTitle title={content.vimeoVideo.oEmbedData.title}/>
        <Global styles={css`
          body, header{
            background: var(--darkgrey) !important;
          }
          .filters{
            display: none;
          }
          `
        } />
        
        <Grid css={css`
          position: relative;
          margin: 0.5rem 0;
        `}>
            <div css={css`
              position: absolute;
              z-index: 99;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: grid;
              padding: 1rem;
              opacity: ${ play ? `0` : `1`};
              pointer-events: ${ play ? `none` : `all`};
            `}>
              <div css={css`
                color: var(--white);
                grid-row: 1;
                grid-column: 1;
              `}>
                <div css={css`margin-bottom: 1rem;`}>
                  <RichText 
                    content={content?.overlayText}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi fugit quae deleniti assumenda laborum quis ullam, qui accusamus repudiandae autem voluptatibus earum commodi, deserunt minus tempora labore amet a eius.
                  </RichText>
                </div>
                <a css={css`color: var(--white);`} target="_blank" href={content?.overlayLink?.url} >
                  <span>{content?.overlayLink?.linkText}</span>
                </a>
              </div>             
              <div css={css`
                grid-row: 1;
                grid-column: 1; 
                align-self: center; 
                justify-self: center; 
                pointer-events: none;
              `}>
                <button onClick={playVideo} css={css`pointer-events: all;`}>
                  <PlayButton css={css`
                    color: var(--white); 
                    width: 100px; 
                    height: 100px;
                  `}/>
                </button>
              </div>
            </div>
            <div ref={videoContainer} css={css`
              grid-column: span 4;
              position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%;
              iframe{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            `}/>
        </Grid>
      </Layout>
    )
}

export default Video
